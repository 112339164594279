<template src="./template.html"></template>

<script>
export default {
  name: "CustomerEdit",
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        Name: "",
        CustomerID: "",
        Type: "A",
        ChargeServiceRatio: "0",
        Status: "Y",
        NewTime: "2021-04-23",
      },
      defaultItem: {
        Name: "",
        CustomerID: "",
        Type: "A",
        ChargeServiceRatio: "0",
        Status: "Y",
        NewTime: "2021-04-23",
      },
      type_array: [
        {
          label: "儲值客戶",
          value: "A"
        },
        {
          label: "非儲值客戶",
          value: "B"
        }
      ],
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.valid = true
    },
    Close() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
      this.valid = true
    },
    Validate() {
      this.$refs.CustomerForm.validate()
      setTimeout(() => {
        this.valid ? this.CreateCustomer() : ""
      }, 100);
    },
    async CreateCustomer() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/customer", "patch", { Data: this.editedItem })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功更新客戶" })
      }
    }
  },
  created() { },
}
</script>