<template src="./template.html"></template>

<script>
export default {
  name: "CustomerDelete",
  data() {
    return {
      CustomerID: 0,
      dialog: false
    }
  },
  methods: {
    Open(id) {
      this.CustomerID = parseInt(id)
      this.dialog = true
    },
    Close() {
      this.CustomerID = 0
      this.dialog = false
    },
    async DeleteData() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/customer", "delete", { Data: { CustomerID: this.CustomerID } })
      this.$store.commit("SetLoading", false)
      if (response != "error") {
        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功刪除客戶" })
      }
    }
  }
}
</script>