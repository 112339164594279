<template src="./template.html"></template>

<script>
export default {
  name: "CustomerDesposit",
  props: {
    customers: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        CustomerID: 0,
        Amount: 0
      },
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open() {
      this.valid = true
      this.dialog = true
    },
    Close() {
      this.valid = true
      this.dialog = false
      this.editedItem = {
        CustomerID: -1,
        Amount: 0
      }
    },
    Validate() {
      this.$refs.CustomerForm.validate()

      setTimeout(() => {
        this.valid == true ? this.Desposit() : ""
      }, 100);
    },
    async Desposit() {
      let now_customer = this.customers.filter(item => item.CustomerID == this.editedItem.CustomerID)[0]
      let Data = {
        Type: "I",
        CustomerID: this.editedItem.CustomerID,
        ProjectID: 0,
        Amount: this.editedItem.Amount,
        AccountDate: new Date().Format("yyyy-MM-dd"),
        Remark: now_customer.Name + " - 儲值",
        MoneyItemID: 3
      }

      if (this.editedItem.Amount < 0) {
        Data.Amount = Math.abs(this.editedItem.Amount)
        Data.Type = "O"
        Data.Remark = now_customer.Name + " - 註銷儲值"
      }



      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/money", "post", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {
        this.$emit("load-data")
        this.Close()
        this.$store.commit("SetSnackBar", { status: true, content: "已成功儲值" })
      }
    }
  },
  computed: {
    deposit_customers() {
      return this.customers.filter(item => item.Type == "A")
    }
  }
}
</script>