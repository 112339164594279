var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-md-10 pa-sm-1 pa-3",attrs:{"id":"Customers"}},[_c('CreateCustomer',{ref:"CreateCustomer",on:{"load-data":_vm.LoadData}}),_c('EditCustomer',{ref:"EditCustomer",on:{"load-data":_vm.LoadData}}),_c('DeleteCustomer',{ref:"DeleteCustomer",on:{"load-data":_vm.LoadData}}),_c('DepositCustomer',{ref:"DepositCustomer",attrs:{"customers":_vm.customers},on:{"load-data":_vm.LoadData}}),_c('ViewCustomer',{ref:"ViewCustomer"}),_c('v-card',{attrs:{"color":"#fff"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"footer-props":_vm.footerprops,"items":_vm.customers,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(_vm.account().role != 'V')?_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.OpenCreate}},[_vm._v(" 新增客戶 ")]):_vm._e(),(_vm.account().role == 'A')?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","dark":""},on:{"click":_vm.OpenDeposit}},[_vm._v(" 儲值 ")]):_vm._e()],1)]},proxy:true},{key:"item.View",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.OpenView(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("status")(item.Status))+" ")]}},{key:"item.Admin",fn:function(ref){
var item = ref.item;
return _vm._l((item.Admin),function(user){return _c('v-chip',{key:'user_'+user,staticClass:"mr-1"},[_vm._v(_vm._s(_vm.GetAccount(user))+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.account().role != 'V')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.OpenEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.account().role == 'A')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.OpenDelete(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("目前沒有資料")])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }