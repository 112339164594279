<template src="./template.html"></template>

<script>
import CreateCustomer from "@/components/Customers/CreateDialog/index"
import EditCustomer from "@/components/Customers/EditDialog/index"
import DeleteCustomer from "@/components/Customers/DeleteDialog/index"
import DepositCustomer from "@/components/Customers/DepositDialog/index"
import ViewCustomer from "@/components/Customers/ViewDialog/index"
export default {
  name: "CustomersPage",
  components: {
    CreateCustomer,
    EditCustomer,
    DeleteCustomer,
    DepositCustomer,
    ViewCustomer
  },
  data() {
    return {
      headers: [
        {
          text: '檢視',
          align: 'start',
          value: 'View',
          width: 80
        },
        {
          text: '客戶編號',
          align: 'start',
          value: 'CustomerID',
        },
        {
          text: '客戶名稱',
          align: 'start',
          value: 'Name',
        },
        {
          text: '狀態',
          align: 'start',
          value: 'Status',
        },
        {
          text: '品牌顧問',
          align: 'start',
          value: 'Admin',
        },
        { text: '建立日期', value: 'NewTime' },
        { text: '動作', value: 'actions', sortable: false },
      ],
      footerprops: {
        'items-per-page-text': '每頁顯示',
      },
      customers: [],
      customer_auth: null,
      users: []
    }
  },

  created() {
    this.LoadAccountData()
  },

  methods: {
    OpenCreate() {
      this.$refs.CreateCustomer.Open()
    },
    OpenView(item) {
      this.$refs.ViewCustomer.Open(item)
    },
    OpenDeposit() {
      this.$refs.DepositCustomer.Open()
    },
    OpenEdit(item) {
      this.$refs.EditCustomer.Open(item)
    },
    OpenDelete(item) {
      this.$refs.DeleteCustomer.Open(item.CustomerID)
    },
    GetAccount(id) {
      let user = this.users.filter(item => item.AdminID == id)
      return user.length > 0 ? user[0].Nickname : ""
    },
    async LoadData() {
      this.loading = true
      let response = await this.GetData("/admin/customer")
      this.loading = false
      if (response != "error") {

        let Data = response
        Data.forEach(item => {
          Object.keys(this.customer_auth).forEach(customer => {
            item.CustomerID == customer ? item.Admin = this.customer_auth[customer] : ""
          })
        });
        this.customers = []
        if (this.account().role == "C") {
          Data.forEach(item => {
            if (item.Admin.filter(account => account == this.account().id).length > 0) {
              this.customers.push(item)
            }
          })
        }
        else {
          this.customers = Data
        }
      }
    },
    async LoadAdminData() {
      this.loading = true
      let response = await this.SendData("/admin/customer/auth/get", "post", { Data: {} })
      this.loading = false
      if (response != "error") {

        this.customer_auth = response
        this.LoadData()
      }
    },
    async LoadAccountData() {
      this.loading = true
      let response = await this.GetData("/admin/admin")
      this.loading = false
      if (response != "error") {

        this.users = response
        this.LoadAdminData()
      }
    },
    account() {
      if (this.$cookie.get("account") == null) {
        this.$store.commit("SetSnackBar", {
          status: true,
          content: "帳號驗證錯誤，請重新登入"
        })
        this.$cookie.delete('account');
        this.$cookie.delete('user_token');
        this.$router.push("/login")
        return { name: "", role: "" }
      }
      else {
        return JSON.parse(this.$cookie.get("account"))
      }
    }
  },

  filters: {
    status(val) {
      return (val == 'Y') ? "已啟用" : "已停用";
    },
  }
}
</script>