<template src='./template.html'></template>

<script>
export default {
  name: "ProjectView",
  data() {
    return {
      dialog: false,
      date_menu: false,
      date: "2021-05",
      customer: null,
      balance: null,
      bills: null,
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
      status_array: {
        Y: "已啟用",
        N: "已停用",
      },
    }
  },
  methods: {
    Open(item) {
      this.customer = Object.assign({}, item)
      this.dialog = true
      this.date = new Date().Format("yyyy-MM")
      this.LoadData()
      this.LoadBalanceData()
    },
    Close() {
      this.customer = null
      this.dialog = false
    },
    DateSave(date) {
      this.$refs.date_menu.save(date)
      this.LoadData()
      this.LoadBalanceData()
    },
    StatusColorFilter(status) {
      let colors = {
        W: "blue-grey lighten-2 white--text",
        P: "blue-grey darken-4 white--text",
        Y: "green darken-3 white--text",
        E: "deep-orange darken-4 white--text",
        N: "amber accent-4 white--text"
      }
      return colors[status]
    },

    async LoadData() {
      let now_year = parseInt(this.date.substr(0, 4))
      let now_month = parseInt(this.date.substr(5, 2))
      this.loading = true
      let response = await this.SendData("/admin/money/" + this.customer.CustomerID, "post", {
        "StartDate": new Date(now_year, now_month - 1, 1).Format("yyyy-MM-dd"),
        "EndDate": new Date(now_year, now_month, 0).Format("yyyy-MM-dd")
      })
      this.loading = false
      if (response != "error") {

        this.bills = response
        this.bills = this.bills.sort((a, b) => {
          let date_a = new Date(a.AccountDate)
          let date_b = new Date(b.AccountDate)
          return date_a - date_b
        })
      }
    },
    async LoadBalanceData() {
      let now_year = parseInt(this.date.substr(0, 4))
      let now_month = parseInt(this.date.substr(5, 2))
      this.loading = true
      let response = await this.SendData("/admin/money/balance/" + this.customer.CustomerID, "post", {
        "StartDate": new Date(now_year, now_month - 1, 1).Format("yyyy-MM-dd"),
        "EndDate": new Date(now_year, now_month, 0).Format("yyyy-MM-dd")
      })
      this.loading = false
      if (response != "error") {

        this.balance = response.EndBalance
        this.balance == null ? this.balance = 0 : ""
      }
    },


    project_total_price() {
      let money = 0
      if (this.bills != null) {
        this.bills.forEach(item => {
          if (item.MoneyItemID == 2) {
            money += parseInt(item.Amount)
          }
        });
      }
      return money
    },
    charge_total_price() {
      let money = 0
      if (this.bills != null) {
        this.bills.forEach(item => {
          if (item.MoneyItemID == 1) {
            money += parseInt(item.Amount)
          }
        });
      }
      return money
    },
  },
  computed: {
    service_charge() {
      let now_customer = this.customers.filter(item => item.CustomerID == this.editedItem.CustomerID)
      if (now_customer.length <= 0) {
        return ""
      } else {
        let charge_percent = now_customer[0].ChargeServiceRatio
        charge_percent = parseInt(charge_percent) * 0.01
        return parseInt(this.editedItem.Budget) * charge_percent
      }
    },
  },
  filters: {
    currency(price) {
      let new_price = Math.abs(parseInt(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return (parseInt(price) < 0) ? "- $" + new_price : " $" + new_price
    },
    type(type) {
      return (type == "A") ? "儲值客戶" : "非儲值客戶"
    }
  }
}
</script>